<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <!-- <h2
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600"
          >
            Summary Report
          </h2> -->
          <ul class="col-6 nav nav-pills mb-2">
            <a
              v-if="this.$store.getters.getAccess?.is_home"
              style="
                font-size: 14px;
                line-height: 11px !important;
                text-align: center;
                display: inline flow-root list-item;
                cursor: pointer;
              "
              class="nav-link box-border-tab"
              :class="{ active: currentTab == 'DASHBOARD' }"
              v-on:click="changeTab('DASHBOARD')">
              <span style="">Dashboard</span></a
            >
            <a
              v-if="this.$store.getters.getAccess?.is_summary"
              style="
                font-size: 14px;
                line-height: 11px !important;
                cursor: pointer;
                width: 102px !important;
              "
              class="nav-link box-border-tab"
              :class="{ active: currentTab == 'SUMMARY' }"
              v-on:click="changeTab('SUMMARY')">
              <span style="color: black">Summary</span></a
            >
            <a
              v-if="this.$store.getters.getAccess?.is_user"
              style="
                font-size: 14px;
                line-height: 11px !important;
                cursor: pointer;
                width: 102px !important;
              "
              class="nav-link box-border-tab"
              :class="{ active: currentTab == 'USER' }"
              v-on:click="changeTab('USER')">
              <span style="color: white">USER</span></a
            >
          </ul>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <div class="col-lg-12 row">
            <div class="col-lg-2 mt-1">
              <div class="input-group mb-2">
                <Field
                  type="text"
                  class="form-control"
                  name="ledger_balance"
                  placeholder="SEARCH"
                  v-model="keyword"
                  v-on:keyup="getList()" />
              </div>
            </div>

            <div class="col-lg-1">
              <!-- <button
                  id="btn-search"
                  type="button"
                  v-on:click="getList(1)"
                  class="btn btn-primary mb-2"
                >
                  Search
                </button>
                &nbsp; -->
              <button
                type="button"
                v-on:click="getList(0)"
                class="btn btn-secondary mb-2">
                Refresh
              </button>
            </div>
            <!-- <div class="col-lg-4"></div>
            <div
              class="col-lg-2"
              style="
                font-weight: bold;
                margin-top: 10px;
                text-align: initial;
                font-size: 13px;
              "
            >
              Total Live M2M : {{ count.toFixed(2) }}
            </div> -->
          </div>
          <table
            id="table"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered-summary product-list table-hover">
            <thead>
              <tr>
                <th
                  class="head sorting"
                  id="id-code"
                  v-on:click="sorting('code', 'id-code')">
                  Code
                </th>
                <th
                  class="head sorting"
                  id="id-name"
                  v-on:click="sorting('name', 'id-name')">
                  Name
                </th>
                <th
                  class="head sorting"
                  id="id-role"
                  v-on:click="sorting('role', 'id-role')">
                  User Type
                </th>
                <th
                  class="head sorting"
                  id="id-source"
                  v-on:click="sorting('source', 'id-source')">
                  Source
                </th>
                <th
                  class="head sorting"
                  id="id-last_login_ip"
                  v-on:click="sorting('last_login_ip', 'id-last_login_ip')">
                  Ip
                </th>
                <th
                  class="head sorting"
                  id="id-last_login"
                  v-on:click="sorting('last_login', 'id-last_login')">
                  Last Login
                </th>
                <th class="head">View</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index">
                <td class="body-latest" style="text-align: initial">
                  {{ $helperService.getUpperCase(data.code) }}
                </td>
                <td class="body-latest" style="text-align: initial">
                  {{ $helperService.getUpperCase(data.name) }}
                </td>
                <td class="body-latest" style="text-align: initial">
                  {{ data.role.replace("_", " ") }}
                </td>
                <td class="body-latest" style="text-align: initial">
                  {{ data.source ? data.source : "-" }}
                </td>
                <td class="body-latest" style="text-align: initial">
                  {{ data.last_login_ip ? data.last_login_ip : "-" }}
                </td>
                <td class="body-latest" style="text-align: initial">
                  {{
                    data.last_login
                      ? $helperService.getDateTime(data.last_login)
                      : ""
                  }}
                </td>
                <td class="body-latest">
                  <a
                    style="color: #f55943; cursor: pointer"
                    v-on:click="showOrderModel(data.id)">
                    View
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(0)"
      ref="deleteItem" />
    <IpModal ref="orderModal" />
  </div>
</template>
<script>
import { Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import IpModal from "@/components/UserIp.vue";
export default {
  created() {
    let that = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.keyCode === 27) {
        that.$refs.orderModal.closeModal();
        that.$refs.tradesModal.closeModal();
        that.$refs.positionModal.closeModal();
        that.keyword = "";
        that.getList(0);
      }
    });
  },
  components: {
    Field,
    ErrorComponent,
    IpModal,
  },
  name: "Profile",
  data() {
    return {
      currentTab: "USER",
      role: this.$store.getters.getUser?.role,
      loader: true,
      list: [],
      broker_list: [],
      master_list: [],
      keyword: "",
      master_id: "",
      broker_id: "",
      count: 0,
    };
  },
  mounted() {
    this.checkAccess();
    this.getList(0);
    this.getBrokerList();
    this.getMasterList();
  },
  methods: {
    checkAccess() {
      var access = this.$store.getters.getAccess;
      if (access.is_user == 0) {
        this.$router.push("/access-denied");
      }
    },
    getBrokerList() {
      this.$api
        .getAPI({
          _action: "/broker-list",
        })
        .then((res) => {
          this.broker_list = res;
        })
        .catch(() => {});
    },
    getMasterList() {
      this.$api
        .getAPI({
          _action: "/master-list",
        })
        .then((res) => {
          this.master_list = res;
        })
        .catch(() => {});
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(obj.order_by, obj.sort_by);
    },
    getList(orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};

      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (this.master_id) {
        searchObj.master_id = this.master_id;
      }
      if (this.broker_id) {
        searchObj.broker_id = this.broker_id;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }
      // searchObj.role = "USER";
      this.$api
        .getAPI({
          _action: "/user-login-log-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;
          for (var i in this.list) {
            this.count = +Number(this.list[i].total_m2m);
          }
          res.list = this.list;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Date available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.keyword = "";
      this.getList(0);
    },
    showOrderModel(id) {
      this.$refs.orderModal.showModal(id);
    },
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "DASHBOARD") {
        this.$router.push("/dashboard");
      }
      if (tab == "SUMMARY") {
        this.$router.push("/summary-report");
      }
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
